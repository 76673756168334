import { defineMessages } from 'react-intl'

export const nudgeMessages = defineMessages({
    testButton: {
        defaultMessage: 'CTA Click',
        description: 'cta click button'
    },
    resetNudgeMessage: {
        defaultMessage:
            'Now would be a great time to reduce stress in 60 seconds with a Reset.',
        description: `reset nudge message`
    },
    watchResetButton: {
        defaultMessage: 'Watch Reset',
        description: 'watch reset button'
    },
    challengeReminderNudgeMessage: {
        defaultMessage:
            'You can earn more plants! Complete all your daily Challenge goals.',
        description: 'challenge nudge message'
    },
    viewChallengeButton: {
        defaultMessage: 'Go to Challenges',
        description: 'view challenge button'
    },
    browserNotificationPermissionTitle: {
        defaultMessage: 'Stay on top of your goals with browser notifications',
        description: 'browser notification permission title'
    },
    newAchievementTitle: {
        description: 'New achievement title',
        defaultMessage:
            "Congratulations, you've earned {count, plural, one {a new Achievement!} other {new Achievements!}}"
    },
    seeMyAchievements: {
        defaultMessage: 'See my Achievements',
        description: 'see my achievements button'
    },
    allow: {
        defaultMessage: 'Allow',
        description: 'allow button'
    }
})
